import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  filterOutDocs,
  mapEdgesToNodes,
  getBlogUrl,
  sliceIntoChunks,
} from "../lib/helpers";
import BlogPostPreview from "../components/blog-post-preview";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import PortableText from "../components/portableText";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query CategoryPageQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    page: sanitySitePage(slug: { current: { eq: "blog" } }) {
      id
      mainImage {
        ...SanityImage
        alt
      }
      cardImage {
        ...SanityImage
        alt
      }
      title
      description
      slug {
        current
      }
    }
    category: sanityCategory(id: { eq: $id }) {
      title
      _rawDescription
      slug {
        current
      }
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        categories: { elemMatch: { id: { eq: $id } } }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...ImageWithPreview
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const Category = (props) => {
  const { location, data, errors } = props;
  const { page = {}, category } = data;

  const [currentIndex, setCurrentIndex] = useState(0);

  const loadMoreAction = (e) => {
    e.preventDefault();
    setCurrentIndex(currentIndex + 1);
  };

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  let postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  postNodes = sliceIntoChunks(postNodes, 6);

  let currentPostData = [];
  for (let i = 0; i < postNodes.length; i++) {
    currentPostData = [...currentPostData, ...postNodes[i]];
    if (i >= currentIndex) {
      break;
    }
  }

  return (
    <Layout>
      <SEO {...page} location={location} />
      <div className="main">
        <div className="heroBanner-innerPage blog-banner">
          <section className="banner-caption  d-flex">
            <div className="container d-flex align-content-center flex-wrap">
              <div className="banner-caption_inner  ">
                <h1>Blog Category</h1>
                <p className="paragraph-large breadcrumb ">
                  <Link to="/">Home</Link>{" "}
                  <span className="icon-arrow_forward"></span>
                  <Link to="/blog">Blog</Link>
                  <span className="icon-arrow_forward"></span>
                  {category.title}
                </p>
              </div>
            </div>
          </section>
        </div>

        <section className="blog-grid pt-5 pb-5 grey-bg2">
          <div className="container">
            <div className="heading-n-description pb-md-5 ">
              <h2>Blogs by {category.title}</h2>
              <p>
                <PortableText blocks={category._rawDescription} />
              </p>
            </div>
            <div className="row">
              {currentPostData &&
                currentPostData.map((node) => (
                  <BlogPostPreview key={node.id} {...node} isInList />
                ))}
            </div>
            {currentIndex + 1 < postNodes.length && (
              <div className="text-center ">
                <a href="#" className="btn orange" onClick={loadMoreAction}>
                  Load More
                </a>
              </div>
            )}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Category;
